import React, {useState, useEffect} from 'react';
import {  makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { useHistory, useLocation} from "react-router-dom";
import { Tabs, Tab, useMediaQuery } from '@material-ui/core';
import './overrides.css'


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    appBar: {
      height: '160px',
      width: '100%',
      background: 'transparent',
      postion: 'relative',
    },
    menuButton: {
      marginRight: theme.spacing(1),
      color: 'white',      
    },
    link: {
        fontSize: '2.2rem',
        fontFamily: 'Bebas Neue',
        color: '#212121',
        textDecoration: 'none',
        lineHeight: '35px',

        [theme.breakpoints.down("sm")]: {
            fontSize: '1.5rem'
        }
    },
    spacer: {
        height: '25px'
    }
  }));

  

function Bar () {
    const classes = useStyles();
    let history = useHistory()
    const [tabIndex, setTabIndex] = useState(0)
    const location = useLocation()
    const isPhone = useMediaQuery('(max-width: 600px)')

    useEffect(() => {
        console.log(location.pathname)
        switch (location.pathname) {
            case "/":
                setTabIndex(0)
                break;
            case "/decorating":
                setTabIndex(1)
                break;
            case "/carpentry":
                setTabIndex(2)
                break;
            default:
                setTabIndex(0)
                break;
        }
    }, [])

    const handleChange = (event, newValue) => {
        setTabIndex(newValue)

        newValue == 0 ? history.push("/")
        : newValue == 1 ? history.push("/decorating")
        : newValue == 2 ? history.push("/carpentry")
        : newValue = 0
    }

    return (
        <div className={classes.root}>
            <AppBar elevation={0} position="relative"  className={classes.appBar}>
                <div className={classes.spacer}/>
                <Tabs
                    className={classes.tabs}
                    value={tabIndex}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant={isPhone ? "fullWidth" : "standard"}
                    centered
                    scrollButtons="auto"
                    onChange={handleChange}
                    >
                        <Tab label="Home" />
                        <Tab label="Decorating"/>
                        <Tab label="Carpentry"/>
                    </Tabs>
            </AppBar>
    </div>
    )
}

export default Bar;