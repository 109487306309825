import React, { useEffect } from 'react';
import {makeStyles} from '@material-ui/core'
import Bar from './Components/Bar'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import TransitionSwitch from 'react-router-transition-switch'
import Fader from 'react-fader'
import Index from './Components/Index'
import Decorating from './Components/Decorating'
import Carpentry from './Components/Carpentry'
import Triangle from './Components/Triangle'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './App.css'

import ReactGA from 'react-ga'

ReactGA.initialize('UA-174915349-1');
ReactGA.pageview(window.location.pathname + window.location.search);
const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    minHeight: '100vh'
  },
  wrapper: {
    paddingBottom: '85px',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '270px'
    },
  }
}))

function App() {
  useEffect(() => {
    AOS.init({
      delay: '450',
      duration: '1000'
    })
  })
  const classes = useStyles()
  return (
    <Router>
     <div className={classes.container}>
      <div className={classes.wrapper}>
        <Bar/>
        <TransitionSwitch component={Fader}>
          <Route path="/decorating" component={Decorating} />
          <Route path="/carpentry" component={Carpentry} />
          <Route path="/" component={Index}/>
        </TransitionSwitch>
      </div>
      <Triangle/>
     </div>
      
    </Router>
  );
}

export default App;
