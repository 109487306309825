import React from 'react';
import {  makeStyles, Typography, Container, Grid, useMediaQuery } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import EmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';


import {ReactComponent as Jack} from './static/Jack.svg'

const useStyles = makeStyles(theme => ({
    footer: {
        width: '100%',
        height: '120px',
        position: "absolute",
        bottom: '0',
        

        [theme.breakpoints.down("xs")]: {
            height: '270px',
            textAlign: 'left',
        }
    },
    info: {
        lineHeight: '15px',
        fontFamily: 'Nunito',
        color: 'black',
        textDecoration: 'none',
        fontSize: '1rem',
        textAlign: 'left',

        [theme.breakpoints.down('sm')]: {
            marginBottom: '15px'
        }
    },
    facebook: {
        textDecoration: 'none',
        color: '#212121',
        display: 'block'
    },
    jaack: {
        position: 'relative',
        padding: '0px',
        bottom: '-14px',

        [theme.breakpoints.down("sm")]: {
            marginLeft: '-7px'
        },
        '&:hover': {
            animation: 'rotate 2s infinite'
        }
    },
    jaackLink: {
        textDecoration: 'none',
        color: '#212121',
        textAlign: 'center',
    },
    jaackText: {
        textAlign: 'left',
        marginTop: '-25px',
        marginLeft: '50px',
        fontSize: '1rem',
    },
    contactBreak: {
        marginTop: '5px',
        marginBottom: '0px',
    },
    icons: {
        padding: '0px',
        position: 'relative',
        bottom: '-6px',
        fontSize: '1.2rem',
    },
    footerHead: {
        fontFamily: 'Bebas Neue',
        fontSize: '1.2rem'
    }
}))

export default function Triangle() {
    const isPhone = useMediaQuery('(max-width: 600px)')
    const classes = useStyles()
    return(
        <div>
            <footer className={classes.footer}>
                <Grid container justify="space-evenly">
                    <Grid item xs={isPhone && 12}>
                        <Container>
                            <Typography className={classes.info}>
                                <span className={classes.footerHead}> Decorating - Chase Bond</span> <hr className={classes.contactBreak}/>
                              <EmailIcon className={classes.icons}/>  <a className={classes.info} href="mailto:chasebond11@live.co.uk">chasebond11@live.co.uk</a> <br/>
                              <PhoneIphoneIcon className={classes.icons}/> <a className={classes.info} href="tel:07923913029">07923913029</a>
                            </Typography>
                            {isPhone && <br/>}
                        </Container>
                    </Grid>
                    <Grid item xs={isPhone && 12}>
                        <Container>
                            <Typography className={classes.info}>
                                   <span className={classes.footerHead}> Carpentry / Building - James Bond</span> <hr className={classes.contactBreak}/>
                               <EmailIcon className={classes.icons}/> <a className={classes.info} href="mailto:james.bond66@btinternet.com">james.bond66@btinternet.com</a> <br/>
                               <PhoneIphoneIcon className={classes.icons}/> <a className={classes.info} href="tel:07836596449">07836596449</a> 
                            </Typography>
                            {isPhone && <br/>}
                        </Container>
                    </Grid>
                    <Grid item xs={isPhone && 12}>
                        <Container>
                            <Typography className={classes.info}>
                                <span className={classes.footerHead}> Facebook Page</span> <hr className={classes.contactBreak}/>
                               <a target="_blank" rel="noopener noreferrer" className={classes.facebook} href="https://www.facebook.com/BondCarpentryandDecorating/"><FacebookIcon className={classes.icons}/>&nbsp; @BondCarpentryandDecorating  </a>
                                <a target="_blank" rel="noopener noreferrer" className={classes.jaackLink} href="https://www.jaack.dev"> <Jack className={classes.jaack}/><p className={classes.jaackText}>Website created by <br/> www.jaack.dev</p></a> 
                            </Typography>
                        </Container>
                    </Grid>
                </Grid>
            </footer>
        </div>
    )
}