import React from 'react';
import {  makeStyles, Typography, Grid, Container } from '@material-ui/core'
import Service from './Service'

import Conversion from './static/showcase/conversion.jpeg'
import Extension from './static/showcase/extension.jpg'
import Loft from './static/showcase/loft.jpeg'

import Garage from './static/showcase/garage.jpeg'
import Cabin from './static/showcase/cabin.jpeg'

import Kitchen from './static/showcase/kitchen.jpg'

import Bathroom from './static/showcase/bathroom.jpeg'
import Stairs from './static/showcase/stairs.jpg'
import Door from './static/showcase/doors.jpg'
import Floor from './static/floor.jpeg'
import Boarding from './static/showcase/boarding.jpg'

const useStyles = makeStyles(theme => ({
    spacer: {
        height: '160px',

    },
    spacer2: {
        height: '80px',
    },
    heading: {
        fontFamily: 'Bebas Neue',
        fontSize: '2.5rem',
        display: 'block',
        textAlign: 'center',
        margin: '20px',
    },
    sub: {
        fontFamily: 'Nunito',
        fontSize: '1.3rem',
        textAlign: 'center',
        margin: '1rem 8rem 1rem 8rem',

        [theme.breakpoints.up("lg")]: {
            margin: '1rem 24rem 1rem 24rem'
        },

        [theme.breakpoints.down("xs")]: {
            margin: '0.5rem 1rem 0.5rem 1rem'
        }
    }
}))

export default function Carpentry() {
    const classes = useStyles()
    return(
        <div>
            <heading className={classes.heading}>Carpentry & Building Services</heading>
            <Typography className={classes.sub}>
                Our Carpentry and Building services will add style and value to your property. Our highly skilled and dedicated team will work in partnership with you to achieve the wow factor your home deserves.
            </Typography>
            <Container className={classes.serviceContainer}>
                <Grid 
                    container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                    >
                    <Grid item xs={10}>
                        <Service
                        title="Property Refurbishments"
                        image={Conversion}
                        text="Experts in carpentry and joinery, we take pride in the fact that our renovation projects are carried out and finished to the highest standard,
                        using highly skilled craftsmen. Our flexibility means we can undertake single room refurbishments through to complete buildings."
                        direction="row-reverse"
                        />
                        <Service
                        title="Extensions"
                        image={Extension}
                        text="With the rise in property prices in recent years, many homeowners are looking to extend their existing property to create more space. Our team of skilled tradesmen have the expertise to build an extension perfect for your home."
                        direction="row"
                        />
                        <Service
                        title="Loft Conversions"
                        image={Loft}
                        text="Loft conversions are an affordable way of adding space to your property and can be customised to suit your needs. 
                        A loft conversion can unlock value in your home by converting unused space into a living area to realise your property’s full potential. 
                        No need to move house – simply make the best use of the space you already have. Our skilled professionals are here to help."
                        direction="row-reverse"
                        />
                        <Service
                        title="Garage Conversions"
                        image={Garage}
                        text="A good quality garage conversion that has been well planned and executed can add as much as 10% to the value of your home. As well as adding value, a garage conversion provides additional living space to meet your individual needs. 
                        We would be delighted to help with your vision for transforming your garage into your very own special space and delivering your project to the highest specification. "
                        direction="row"
                        />
                        <Service
                        title="Cabins & Garden Rooms"
                        image={Cabin}
                        text="Have you ever wished you had more space to relax, unwind and enjoy life? You could have your own gym, an office or an extra bedroom for guests; the possibilities are endless and completely personal to you.
                        Our experts can provide a garden room that brings both style and functionality to your garden."
                        direction="row-reverse"
                        />
                        <Service
                        title="Kitchens"
                        image={Kitchen}
                        text="Renovating your kitchen can make a huge difference to your home and also increase the value of your property as well as your enjoyment of the hub of the home.
                        We undertake all aspects of kitchen fitting to your bespoke requirements, all finished to an exceptional standard."
                        direction="row"
                        />
                        <Service
                        title="Bathrooms"
                        image={Bathroom}
                        text="Make your bathroom as unique as you are. Our experienced, trustworthy team will carry out every step of the installation to ensure you realise the bathroom you deserve to your bespoke specification."
                        direction="row-reverse"
                        />
                        <Service
                        title="Stair Upgrades"
                        image={Stairs}
                        text="We can provide a totally bespoke staircase just for you, designed to fit perfectly into your home and suit the lifestyle that you lead, as well as your budget.
                        Traditional, contemporary, wood, glass or metal? The choice is yours. Our expert joiners will ensure your staircase is installed and finished to a premium quality."
                        direction="row"
                        />
                        <Service
                        title="Doors & Frames"
                        image={Door}
                        text="We understand that if you are looking to employ someone to install your doors and framing, then you are looking for reliability, professionalism and above all else skilled workmanship. We meet those needs and are here to help."
                        direction="row-reverse"
                        />
                        <Service
                        title="Skirting & Flooring"
                        image={Floor}
                        text="Although furnishings will complete the look of your room, it is the flooring and skirting board that will bring it all together. Whatever material you choose, our team of professionals will deliver a quality finish, giving you the perfect canvas for your room."
                        direction="row"
                        />
                        <Service
                        title="Loft Boarding"
                        image={Boarding}
                        text="Professionally boarding out your loft can create upwards of 50% extra usable space. Think about that. How many other areas of your home would you be able to de-clutter with that extra space?&nbsp;
                        Our expert joiners are ready to solve your storage issues."
                        direction="row-reverse"
                        />
                        <br/>
                    </Grid>
                    <Typography className={classes.sub}>Contact our carpentry and construction specialist James Bond below for a free, no obligation quote.</Typography>
                </Grid>
            </Container>
            <div className={classes.spacer2}/>
        </div>

    )
}