import React from 'react';
import {  makeStyles, Typography, useMediaQuery, Card, CardMedia, Grid} from '@material-ui/core'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Kitchen2 from './static/kitchen.png'
import FeatureWall from './static/featurewall.png'
import House from './static/house.jpeg'
import House2 from './static/house2.jpeg'
import House3 from './static/house3.jpeg'
import Floor from './static/floorsquare.jpeg'
import './overrides.css'
import Paintbrush from './static/paintbrush.svg'
const useStyles = makeStyles(theme => ({
    spacer: {
        height: '160px',

    },
    spacer2: {
        height: '50px',
    },
    textbox: {
        backgroundImage: `url(${Paintbrush})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        padding: '1.5rem',
        fontWeight: '600',
        margin: '0rem 6rem 6rem 6rem',
        display: 'inline-block',
        color: '#212121',
        position: 'relative',
        fontSize: '1.5rem',
        textAlign: 'center',

        [theme.breakpoints.down("md")]: {
            top: '25%',
        },
        
        [theme.breakpoints.down("sm")]: {
            fontSize: '1.1rem',
            margin: '0.5rem 1rem 0.5rem 1rem',
            padding: '1rem',
            top: '20%',
        },
    },
    tagline: {
        fontSize: '2rem',
        fontFamily: 'Nunito',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.4rem'
        },
        fontWeight: '700',
        fontStyle: 'italic',
        color: '#212121',
    },
    heading: {
        color: '#212121',
        fontSize: '1.5rem',
        textAlign: 'center',
        fontFamily: 'Nunito',
        letterSpacing: '-0.4px',

        [theme.breakpoints.down('md')]: {
            fontSize: '1.3rem',
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '1.2rem'

        }
    },
    media: {
        height: '40vh',
        objectFit: 'cover',
        background: 'transparent',
        animation: 'kenburns 90s',
        
        [theme.breakpoints.down('md')]: {
            width: '80vw',
        }

    },
    slider: {
        borderRadius: '25px',
        backfaceVisibility: 'hidden',
        transform: 'translateZ(0)',
        margin: '50px',
        [theme.breakpoints.down('md')]: {
            width: '80vw',
        }


    },


    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    link: {
        fontSize: '3.5rem',
        fontFamily: 'Bebas Neue',
        color: '#212121',
        textDecoration: 'none',
        marginBottom: '50px',

        [theme.breakpoints.down("sm")]: {
            fontSize: '1.5rem'
        }
    },
    topPanel: {
        fontFamily: 'Bebas Neue',
        textAlign: 'center',
        fontSize: '4.5rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '3rem',
        },
    },
    sub: {
        fontFamily: 'Nunito',
        fontSize: '2rem',
        [theme.breakpoints.down("sm")]: {
            fontSize: '2rem',
            margin: '20px',
        }
    }
}))


const settings = {
    pauseOnHover: false,
    pauseOnFocus: false,
    fade: true,
    arrows: false,
    dots: false,
    infinte: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    cssEasing: 'linear',
}

export default function Index() {
    const classes = useStyles()
    const isPhone = useMediaQuery('(max-width: 600px)')

    return(
        <div style={{flexGrow: 1}}>
            <div className={classes.container}>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <div className={classes.topPanel}>
                            Bond Carpentry & Decorating
                            <div className={classes.sub}>
                                Home improvement specialists.<br/>
                                Established for over 30 years. <br/>
                                Based in Benfleet, Essex.
                            </div>
                            <div className={classes.spacer2}/>
                        </div>
                    </Grid>
                    <Grid item md={7}>
                        <div className={classes.textbox}>
                            <div className={classes.innerBox}>
                                <Typography data-aos="fade-up" className={classes.heading}><br/>Our team of professionals in all trades has the expertise to handle any home improvement project.
                                From extensions and conversions to wallpapering and painting, we come prepared for any job. {isPhone && <br/>} We remove the hassle from house renovation, delivering a quality
                                product every time. Not convinced? Take a look at some of our decorating and carpentry projects, and call us today.
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={5}>
                        <Slider {...settings} className={classes.slider} >
                            <Card className={classes.card}>
                                <CardMedia
                                className={classes.media}
                                component="img"
                                image={House3}
                                />
                            </Card>
                            <Card className={classes.card}>
                                <CardMedia
                                className={classes.media}
                                component="img"
                                image={FeatureWall}
                                />
                            </Card>
                            <Card className={classes.card}>
                                <CardMedia
                                className={classes.media}
                                component="img"
                                image={Floor}
                                />
                            </Card>
                            <Card className={classes.card}>
                                <CardMedia
                                className={classes.media}
                                component="img"
                                image={Kitchen2}
                                />
                            </Card>
                            <Card className={classes.card}>
                                <CardMedia
                                className={classes.media}
                                component="img"
                                image={House}
                                />
                            </Card>
                            <Card className={classes.card}>
                                <CardMedia
                                className={classes.media}
                                component="img"
                                image={House2}
                                />
                            </Card>
                        </Slider>
                        <div className={classes.spacer2}/>
                    </Grid>
                </Grid>
            </div>
        </div>

    )
}