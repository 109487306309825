import React from 'react';
import {  makeStyles, Typography, Container, Grid } from '@material-ui/core'
import Service from './Service'

import House from './static/showcase/house.jpeg'
import Wallpaper from './static/showcase/wallpaper.jpeg'
import FeatureWall from './static/showcase/featurewall.jfif'
const useStyles = makeStyles(theme => ({
    spacer: {
        height: '160px',

    },
    spacer2: {
        height: '80px',
    },
    heading: {
        fontFamily: 'Bebas Neue',
        fontSize: '2.5rem',
        textAlign: 'center',
        margin: '20px',
    },
    sub: {
        fontFamily: 'Nunito',
        fontSize: '1.3rem',
        textAlign: 'center',
        margin: '1rem 8rem 1rem 8rem',
        
        [theme.breakpoints.up("lg")]: {
            margin: '1rem 24rem 1rem 24rem'
        },

        [theme.breakpoints.down("xs")]: {
            margin: '0.5rem 1rem 0.5rem 1rem'
        },
    }
}))

export default function Decorating() {
    const classes = useStyles()
    return(
        <div>
            <header className={classes.heading}>Decorating Services</header>
            <Typography className={classes.sub}
                >Our decorating services ensure your house has that premium finish. We specialise in all aspects of interior and exterior
                painting and decorating, as well as attractive wallpapering and stunning feature walls.
            </Typography>
            <Container className={classes.serviceContainer}>
                <Grid 
                    container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                    >
                    <Grid item xs={10}>
                        <Service
                        title="Painting & Decorating"
                        image={House}
                        text="A fresh lick of paint may be just what your house needs to look brand new again. We offer both interior and exterior painting services, and can colour match any paint to your specific requirements. From one room to a whole house, no job is too big or too small."
                        direction="row"
                        />
                        <Service
                        title="Wallpapering"
                        image={Wallpaper}
                        text="Our wallpapering experts use innovative methods and the latest equipment to carry out fast and efficient work.  "
                        direction="row-reverse"
                        />
                        <Service
                        title="Feature Walls"
                        image={FeatureWall}
                        text="Why not spice up your home with a visually interesting feature wall? A 3D feature wall is a great way to achieve depth on a usually flat surface, and can really add character to your walls. Any feature wall is possible in any colour you can imagine, whether it's wallpapered, panelled, or even includes built in shelving. "
                        direction="row"
                        />
                        <br/>
                    </Grid>
                    <Typography className={classes.sub}>Contact our decorating specialist Chase Bond below for a free, no obligation quote.</Typography>
                </Grid>
            </Container>
            <div className={classes.spacer2}/>
        </div>

    )
}