import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme =>({
    root: {
        textAlign: 'center'
    },
    title: {
        fontSize: '1.8rem',
        fontFamily: 'Bebas Neue',
        textAlign: 'center',
    },
    media: {
        borderRadius: '10px',
        '@media (max-width: 960px)': {
            height: '350px',
        },
        marginRight: '15px',
        '@media (min-width: 450px)': {
            marginRight: '0px',
            marginTop: '15px',
            [theme.breakpoints.up('md')]: {
                marginLeft: '35px'
            }
        },
    
        
    },
    reverseMedia: {
        borderRadius: '10px',
        '@media (max-width: 960px)': {
            height: '350px',
        },
        '@media (min-width: 450px)': {
            margin: '0px',
            marginTop: '15px'
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '-35px',
            marginBottom: '35px'
        }

    },
    article: {
        fontSize: '1.1rem',
        fontFamily: 'Nunito',
        textAlign: 'centered',
        padding: '10px',
    }
}))
function Service(props) {
    const classes = useStyles();
  return (
    <div className={classes.root}>
            <Grid
            className={classes.main}
            container
            spacing={0}
            alignItems="center"
            justify="center"
            direction={props.direction}
            >
                <Grid item md={4}>
                    <CardMedia
                        component="img"
                        className={props.direction === "row-reverse" ? classes.reverseMedia : classes.media}
                        image={props.image}
                        title={props.title}
                    />
                </Grid>
                <Grid item md={1}/>
                <Grid item md={6}>
                    <Typography className={classes.title}>
                        {props.title}
                    </Typography>
                    <Typography variant='subtitle1' className={classes.date}>
                        {props.date}
                    </Typography>
                    <hr/>
                    <Typography className={classes.article}>
                        {props.text}
                    </Typography>
                    <br/><br/>
                </Grid>
                <Grid item md={1} />
            </Grid>
    </div>
 )}
export default Service